
import GeneralData from "@/modules/items/components/GeneralData.vue";
import useItem from "@/modules/items/composables/useItem";
import toastr from "toastr";
import {onMounted, Ref, ref} from "vue";
import router from "@/router";
import Loading from "@/components/Loading.vue";
import Taxes from "@/modules/items/components/Taxes.vue";
import MeasurementUnits from "@/modules/items/components/MeasurementUnits.vue";
import {ItemCatalogs} from "@/modules/items/interfaces";

export default {
    components: {Taxes, GeneralData, MeasurementUnits, Loading},
    setup() {
        const {clearForm, itemForm, createItem, getCatalogs} = useItem()
        const sending = ref(false)
        const loading = ref(true)
        const catalogs: Ref<ItemCatalogs | null> = ref(null)

        clearForm();
        onMounted(async () => {
            catalogs.value = await getCatalogs()
            loading.value = false
        });

        return {
            sending,
            loading,
            catalogs,
            cancel: () => router.push({name: 'items'}),
            onSubmit: async () => {
                try {
                    sending.value = true
                    const {ok, message} = await createItem(itemForm.value)

                    if (ok) {
                        toastr.success('Su solicitud se ha procesado correctamente.')
                        await router.push({name: 'items'})
                    } else {
                        await toastr.error(message)
                    }
                } finally {
                    sending.value = false
                }
            }
        }
    }
}
